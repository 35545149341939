



















































































































import { VueAgile } from "vue-agile";
import { defineComponent, computed, PropType } from "@vue/composition-api";
import { TileSliderCaptionsType } from "./TileSliderCaptionsType.types";

export default defineComponent({
  name: "TileSlider",
  components: {
    agile: VueAgile,
  },
  props: {
    records: {
      type: Object as PropType<TileSliderCaptionsType>,
    },
  },
  setup(props) {
    const captions = computed(() => props.records);
    const getButtonLink = (path: string) => {
      return path.startsWith("/")
        ? path
        : path.indexOf("://") == -1
        ? "https://" + path
        : path;
    };

    return {
      captions,
      getButtonLink,
    };
  },
});
