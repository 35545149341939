var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"container py-8",class:[
      _vm.captions.data.container_margin_top,
      _vm.captions.data.container_margin_bottom ]},[_c('div',{staticClass:"hidden sm:block"},_vm._l((_vm.captions.records),function(content,index){return _c('div',{key:index},[_c('div',{staticClass:"relative flex pt-6 mb-44 lg:pt-0 lg:mb-12 lg:w-full lg:overflow-hidden"},[_c('div',{staticClass:"flex flex-col items-center justify-center w-full p-6 lg:mb-10 bg-neon-blue-100 min-h-48 lg:min-h-64",class:[
              content.data.image_position === 'text-left'
                ? 'lg:mr-40 lg:pr-32'
                : 'lg:ml-40 lg:pl-32' ]},[_c('img',{staticClass:"self-center",attrs:{"src":content.icon,"alt":""}}),_c('div',{staticClass:"w-full mt-4 mb-4 space-y-4"},[_c('h3',{staticClass:"text-xl leading-tight text-center h2 lg:text-3xl"},[_vm._v(" "+_vm._s(content.data.headline)+" ")]),_c('div',{staticClass:"flex items-center overflow-hidden antialiased text-center rich-text"},[_c('p',{domProps:{"innerHTML":_vm._s(content.data.bodytext)}})])]),_c('BaseButton',{staticClass:"mt-2",class:[content.data.button_type, content.data.button_size],attrs:{"title":content.data.button_text,"href":_vm.getButtonLink(content.data.button_link),"btnType":content.data.button_type}}),(content.image)?_c('img',{staticClass:"object-cover w-48 h-48 mt-8 -mb-40 lg:pt-0 lg:absolute lg:bottom-0 lg:mb-0 lg:w-64 lg:h-64",class:[
                content.data.image_position === 'text-left'
                  ? 'lg:bottom-0 lg:right-0'
                  : 'lg:bottom-0 lg:left-0' ],attrs:{"src":content.image,"alt":""}}):_vm._e()],1)])])}),0),_c('div',{staticClass:"block sm:hidden tile-slider-mobile"},[_c('agile',{attrs:{"infinite":true,"autoplay":false,"speed":800,"centerMode":true,"slidesToShow":1,"timing":'ease-in-out',"navButtons":false}},_vm._l((_vm.captions.records),function(content,index){return _c('div',{key:index},[_c('div',{staticClass:"relative flex pb-32 mx-2"},[_c('div',{staticClass:"relative flex flex-col justify-center p-12 mt-4 mb-4 space-y-4 bg-neon-blue-100",staticStyle:{"padding-bottom":"110px","height":"inherit","min-height":"500px"}},[_c('img',{staticClass:"self-center",staticStyle:{"width":"60px"},attrs:{"src":content.icon,"alt":""}}),_c('h3',{staticClass:"text-xl leading-tight text-center h2 lg:text-3xl"},[_vm._v(" "+_vm._s(content.data.headline)+" ")]),_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(content.data.bodytext)}}),_c('BaseButton',{staticClass:"self-center mt-2",class:[content.data.button_type, content.data.button_size],attrs:{"href":_vm.getButtonLink(content.data.button_link),"title":content.data.button_text,"btnType":content.data.button_type}}),_c('div',{staticClass:"absolute flex self-center justify-center w-full",staticStyle:{"bottom":"-140px"}},[_c('img',{staticClass:"bg-cover",staticStyle:{"width":"200px","height":"200px"},attrs:{"src":content.image,"alt":""}})])],1)])])}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }